import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmSignUp } from 'aws-amplify/auth';
import VerificationInput from "react-verification-input";
import { useSelector } from 'react-redux';

export default function StudentSignUpConfirmation() {
    const navigate = useNavigate();
    const handleButtonClick = (path) => {
        navigate(path)
    }
    const email = useSelector((state) => state.username.username);
    const [verificationCode, setVerificationCode] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const { isSignUpComplete, nextStep } = await confirmSignUp({
                username: email,
                confirmationCode: verificationCode});

            if (isSignUpComplete) {
                console.log("Sign up complete");
                navigate('/student-login')
            }
            else {
                console.log("Sign up not complete");
                console.log(nextStep);
            }
        } catch (error) {
            console.log("username: " + email);
            console.log("code: " + verificationCode)
            console.log(error.message);
        }
    }
    return (
        <div> 
            <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', fontSize: '25px', textAlign: 'center', lineHeight: '2' }}>
                <div className="form-group" style={{ display: 'grid', placeItems: 'center' }}>
                    <label for="confirmationCode"> 
                        Thank you for creating a student profile! <br />
                        Please check your email for a confirmation code.
                    </label>
                    <div> 
                        <VerificationInput value={verificationCode} onChange={setVerificationCode} />
                    </div>
                    <button type="submit" className="btn btn-success btn-block" style={{marginTop: "20px"}}>
                     Submit confirmation code
                     </button>
                </div>
            </form>
        </div>




        // <Stack gap={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', fontSize: '25px', textAlign: 'center', lineHeight: '2' }}>
        //     <div className="p-2"> Thank you for creating a student profile! <br />
        //         Please check your email for a confirmation code.
        //     </div>
        //     <VerificationInput />
        //     <div className="p-2">
        //         <button type="submit" className="btn btn-success btn-block">
        //             Submit confirmation code
        //         </button>
        //     </div>
        // </Stack>
    )
}

