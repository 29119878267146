import React from 'react';

const ServiceCard = ({ title, description }) => {
  const cardStyle = {
    width: '300px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '15px',
  };

  const descriptionStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
  };

  return (
    <div style={cardStyle}>
      <div style={titleStyle}>{title}</div>
      <div style={descriptionStyle}>{description}</div>
    </div>
  );
};

const services = [
  {
    title: 'Engineering Major Choice Selection',
    description: 'As a high school or community college student, it can be difficult to pick which engineering major you want to apply for. Our engineering advisors can help you figure out which engineering major is the best fit for you.',
  },
  {
    title: 'Personalized College Selection',
    description: 'College is four years of your life, so you want to make sure to find a college that is a good fit both academically and socially. From campus type to student population size, our advisors can help you find the college that is the right fit for you.',
  },
  {
    title: 'Essay Writing Support',
    description: 'Writing college essays can be overwhelming! Our advisors can help you draft your essay topics and proof read your application so you show colleges the best version of yourself.',
  },
  {
    title: 'Extracurricular Advising',
    description: 'Studying engineering is a big commitment so participating in engineering prep activities before college can help you figure out which engineering discipline you want to study. Our advisors can help advise you on engineering prep extracurriculars so you can make an informed decision about what to study.',
  },
  {
    title: 'Application Planning',
    description: 'Filling out your college applications can be overwhelming as well! Our advisors can help you fill out your applications and build your extracurricular resume so you can show colleges the best version of yourself.',
  },
  {
    title: 'Mock Interviews',
    description: 'If you are applying to private schools, many of them require interviews. Our advisors can help you prepare by staging mock interviews and giving you feedback on your responses.',
  },
];

const gridContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap', // Allow grid items to wrap to the next line if necessary
    gridTemplateColumns: 'repeat(3, 1fr)', // Define three columns
    gridTemplateRows: 'repeat(2, 1fr)', // Define two rows (optional if cards control their own height)
    padding: '50px', // Add padding around the entire grid for some spacing from the container edges
    justifyContent: 'center', // This centers the grid items in the container when there's extra space
    alignContent: 'center',
    width: '100%',
    maxWidth: '1200px', // Maximum width of the container, adjust as needed
    margin: '0 auto', // Auto margins for horizontal centering
    gap: '60px'
};

const Services = () => {
  return (
    <div style={gridContainerStyle}>
      {services.map((service, index) => (
        <ServiceCard key={index} title={service.title} description={service.description} />
      ))}
    </div>
  );
};

export default Services;
