import React from 'react';

const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  transition: '0.3s',
  width: '270px', // Set the width of the card
  height: '420px',
  backgroundColor: '#F0EDED',
  marginRight: '30px',
  borderRadius:'20px',
  marginBottom: '10px',
};

const headerStyles = {
  padding: '18px 43px',
  color: '#000', // Text color for the header
  fontSize: '22px', // Font size for the header
  fontWeight: 'bold', // Font weight for the header
  borderRadius: '20px 20px 0 0', // Only round the top corners
  textAlign: 'center',
};

const contentStyles = {
  padding: '2px 16px',
  fontSize: '15px',
};

const listStyles = {
  listStyleType: 'disc', // Remove bullets from list
  padding: '0 0 0 1rem',
  margin: '0',
};

const listItemStyles = {
  padding: '2px 0', // Add some padding to each list item
};

const CollegeAdvisingOption = ({header, content, listItems= [], backgroundColor, textColor}) => {
  // Apply passed-in background color or fallback to default
  const combinedCardStyles = {
    ...cardStyles,
    backgroundColor: backgroundColor || cardStyles.backgroundColor,
    color: textColor || cardStyles.textColor,
  };

  const combinedHeaderStyles = {
    ...headerStyles,
    color: textColor || cardStyles.textColor,
  };

  return (
    <div style={combinedCardStyles}>
      <div style={combinedHeaderStyles}>{header}</div>
      <div style={contentStyles}>
        <p>
        {content}
        </p>
        <ul style={listStyles}>
          {listItems.map((item, index) => (
            <li key={index} style={listItemStyles}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollegeAdvisingOption;
