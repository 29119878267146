import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// Define initial state here
const initialState = {
    username: '' // Initial username is an empty string
  };

const usernameSlice = createSlice({
  name: 'username',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    }
  }
});

export const { setUsername } = usernameSlice.actions;

export default configureStore({
  reducer: {
    username: usernameSlice.reducer
  }
});