export const areasOfFocus = [
    "N/A",
    "Additive Manufacturing",
    "Advanced Fluid Mechanics",
    "Aerospace Engineering",
    "Air Pollution",
    "Algorithms",
    "Analog and Digital Communications Systems",
    "Artificial Intelligence",
    "Atmospheric Chemistry",
    "Biomaterials",
    "Biomechanics",
    "Biomedical Imaging",
    "Biomedical Physiology",
    "BioMEMS & Bionanotechnology",
    "Biochemical Engineering",
    "Biological Materials",
    "Biological Transport Phenomena",
    "Biochemical Engineering",
    "Biomaterials",
    "Biomaterials",
    "Biomaterials",
    "Biomolecular & Cell Biology",
    "Carbon Capture and Sequestration",
    "ChemE Economics",
    "Chemical Process Analysis & Design",
    "Chemical Thermodynamics",
    "Cell Engineering",
    "Cloud Infrastructure",
    "Communications Networks",
    "Computer Architecture",
    "Computer Graphics",
    "Computer Vision",
    "Construction Engineering",
    "Convex Optimization",
    "Corrosion",
    "Credit Risk Modeling",
    "Crystallography",
    "Cyber-Physical Systems",
    "Cybersecurity",
    "Data Engineering",
    "Data Mining and Analytics",
    "Data Structures",
    "Database Systems",
    "Derivatives",
    "Dielectric and Magnetic Materials",
    "Digital Signal Processing",
    "Discrete Math & Probability in CS",
    "Dynamics and Control for Autonomous Flights",
    "Economics and Markets",
    "Electronic Materials",
    "Electronic Transducers",
    "Embedded Systems",
    "Energy Economics & Markets",
    "Energy Efficiency",
    "Engineering Economics",
    "Engineering Geology",
    "Engineering Molecules",
    "Engineering Privacy in Software",
    "Environmental Life Cycle Analysis",
    "Environmental Life Cycle Analysis",
    "Engineering Privacy in Software",
    "Engineering Geology",
    "Environmental Chemistry",
    "Environmental Life Cycle Analysis",
    "Environmental Life Cycle Analysis",
    "Equity Markets",
    "Ethics in Computing",
    "Ethics in Data Science",
    "Fabrication",
    "Feedback Control Systems",
    "Financial Risk Management",
    "Fluid Mechanics",
    "Fusion",
    "Groundwater & Seepage",
    "Geosystems & Geoenvironmental Engineering",
    "Imaging",
    "Infrastructure Sensing & Modeling",
    "Integrated Circuits",
    "Internet Architecture and Protocols",
    "Investments",
    "Integer Optimization",
    "Intro to C++",
    "Intro to Chemistry",
    "Intro to Java",
    "Intro to Matlab",
    "Intro to Python",
    "Kinematics",
    "Kinetics & Reaction Engineering",
    "Linear Programming and Network Flows",
    "Linear Algebra",
    "Logistics Network Design",
    "Machine Learning",
    "Magnetism & Electromagnetism",
    "Machine Learning",
    "Magnetic Materials",
    "Materials Characterization",
    "Materials for Energy and Sustainability",
    "Materials Production",
    "Mathematical Methods for Engineers",
    "Mechanical Behavior of Materials",
    "Mechanical Vibrations",
    "Mechatronics",
    "Methods of Manufacturing Improvement",
    "Microelectronics",
    "Molecular Biomechanics",
    "Molecular Biomechanics",
    "Molecular Biomechanics",
    "Multivariable Calculus",
    "Nanomaterials",
    "Natural Language Processing",
    "Neural Networks",
    "Nonlinear and Discrete Optimization",
    "Nuclear Power Engineering",
    "Nuclear Critical Safety",
    "Nuclear Materials",
    "Nuclear Reactions and Radiation",
    "Operating Systems",
    "Optics and Photonics",
    "Phase Transformation & Kinetics",
    "Photovoltaics",
    "Plasma",
    "Polymer Science",
    "Polymers",
    "Power Electronics",
    "Power Systems",
    "Probability and Risk Analysis",
    "Probability and Statistics for Engineers",
    "Privacy Engineering",
    "Protein Informatics",
    "Quantum Computing",
    "Quantum Mechanics",
    "Radioactive Waste",
    "Radiation Biophysics",
    "Reinforced Concrete",
    "Revenue Management",
    "Robotics",
    "Robotics",
    "Robotics",
    "Solid Mechanics",
    "Solid Mechanics",
    "Solid Mechanics",
    "Semiconductors",
    "Signals & Systems",
    "Space Mechanics",
    "Spacecraft Design",
    "Stochastic Processes",
    "Steel Structures",
    "Structural Engineering & Design",
    "Synthetic Biology",
    "Terrestrial Hydrology",
    "Thin-Film Materials",
    "Thermodynamics",
    "Timber",
    "Tissue Engineering",
    "Transport Phenomena",
    "Transport Processes",
    "Tissue Engineering",
    "Vehicle Dynamics and Control",
    "Water Chemistry",
];
