// Footer.js
import React, { useState, useEffect } from 'react';
import Logo from '../images/kazarma-logo.png';

const footerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#343a40',
  color: '#fff',
  padding: '1rem 2rem',
  fontFamily: 'Roboto, sans-serif', // Adding Roboto font family
};

const sloganStyle = {
  fontSize: '1.2rem',
  fontWeight: 'lighter',
};

const contactStyle = {
  textAlign: 'left',
};

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  fontSize: '1rem',
  fontWeight: 'lighter',
  display: 'block',
  marginBottom: '0.5rem',
};

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Adds resppoinsive behavior to the footer to adapt to different screen sizes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const footerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#343a40',
    color: '#fff',
    padding: isMobile ? '1rem' : '1rem 2rem',
    fontFamily: 'Roboto, sans-serif',
    textAlign: isMobile ? 'center' : 'left',
  };

  const sloganStyle = {
    fontSize: '1.2rem',
    fontWeight: 'lighter',
    marginTop: isMobile ? '1rem' : '0',
  };

  const contactStyle = {
    textAlign: isMobile ? 'center' : 'left',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 'lighter',
    display: 'block',
    marginBottom: '0.5rem',
  };

  return (
    <footer style={footerStyle}>
      <div style={{ flex: 1, textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? '20px' : '0px'}}>
        <img src={Logo} style={{ width: '100px' }} alt="Kazarma Logo" />
        <p style={sloganStyle}>becoming an engineer is difficult. get the help you need at <span style={{ color: "#689EF0" }}>kazarma.io</span></p>
      </div>
      <div style={contactStyle}>
        <span>📞</span> (206) 920-7561
        <br></br>
        <span>✉️</span> info@kazarma.io
      </div>
      <div style={{ flex: 1, textAlign: isMobile ? 'center' : 'right', marginTop: isMobile ? '30px' : '0px'}}>
        <a href="/" style={linkStyle}>Home</a>
        <a href="/engineering-tutoring-services" style={linkStyle}>Engineering Tutoring Services</a>
        <a href="/engineering-college-prep-and-advising" style={linkStyle}>Engineering College Prep and Advising</a>
      </div>
    </footer>
  );
}

export default Footer;