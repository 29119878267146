// ServicesSection.js
import React from 'react';
import TutoringServices from './TutoringServices';
import CollegeAdvising from './CollegeAdvising';

const sectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 1rem',
};

function ServicesSection() {
  return (
    <section style={sectionStyle}>
      <TutoringServices />
      <CollegeAdvising />
    </section>
  );
}

export default ServicesSection;
