// CollegeAdvising.js
import React from "react";
import advisingImage from "../../images/home-page-image-2.png";
import Button from "react-bootstrap/Button";

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch', 
    marginBottom: "2rem",
};

const collegeAdvisingStyle = {
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    padding: "1rem",
    borderRadius: "8px",
    width: "100%", // Ensures it takes the full width of its parent
    height: "100%", // Ensures it takes the full height of its parent
    display: 'flex', // Sets the display to flex to use Flexbox properties
    flexDirection: 'column', // Children are stacked vertically
    justifyContent: 'center', // Centers children vertically within the container
};

const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // This centers the image vertically
    width: '100%', // This ensures the container takes full width
};

const buttonStyle = {
    padding: "0.5rem 1rem",
    marginTop: "0.5rem",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
};

const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap", // Allows buttons to wrap on smaller screens
};

const imageStyle = {
    maxWidth: "100%", // Ensures the image is responsive
    height: "auto", // Ensures the image is responsive
};

function CollegeAdvising() {
    return (
        <div className="row" style={containerStyle}>
            <div className="col-12 col-md-6">
                <div style={collegeAdvisingStyle}>
                    <h3 style={{ marginBottom: "20px" }}>
                        Engineering College Prep and Advising
                    </h3>
                    <p>
                        Embarking on an engineering education requires careful planning and
                        preparation. Kazarma's Engineering College Prep and Advising services
                        are here to help aspiring engineers.
                        <br></br>
                        <br></br>
                        Our comprehensive advising program is designed to help high school
                        and community college students to navigate the admissions process for
                        engineering schools.
                        <br></br>
                        <br></br>
                        Our advisors can help with everything from high school class selection,  
                        extracurricular exploration, and crafting your college applications. We ensure
                        that you're not just prepared to be accepted into an engineering college, 
                        but also that you thrive once you're there.
                        <br></br>
                        <br></br>
                        <b>Get started with Kazarma's College Advising services today!</b>
                    </p>
                </div>
            </div>
            <div className="col-12 col-md-3">
                <div style={imageContainerStyle}>
                    <img
                        src={advisingImage}
                        alt="Advising Image"
                        style={imageStyle}
                    />
                </div>
            </div>
        </div>
    );
}

export default CollegeAdvising;
