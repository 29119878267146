// MainBanner.js
import React from "react";
import backgroundImage from "../images/banner-background.png";
import { useNavigate } from "react-router-dom";

const headerStyle = {
  textAlign: "center",
  padding: "10vh 2vw", // Use viewport units for padding
  backgroundColor: "#f8f9fa",
  color: "#212529",
  margin: "0", // Remove margin for the header
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
};

const h1Style = {
  fontSize: "calc(1.25rem + 1vw)", // Responsive font size
  marginBottom: "1rem",
  fontFamily: "Roboto, sans-serif",
};

const ctaButtonsStyle = {
  display: "flex",
  justifyContent: "center", // Center buttons horizontally
  gap: "1rem",
  flexWrap: "wrap", // Allow buttons to wrap to the next line if necessary
};

const buttonStyle = {
  flex: "1", // Each button takes equal space
  maxWidth: "400px", // Limit the maximum width of each button
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "calc(0.75rem + 0.5vw)", // Responsive font size
  fontFamily: "Roboto, sans-serif",
  fontWeight: "bold",
  padding: "0.5rem 1rem", // Adjust padding for buttons
  width: "100%", // Make buttons full width on mobile
};

function MainBanner() {
  const navigate = useNavigate();
  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <header style={headerStyle}>
      <h1 style={h1Style}>becoming an engineer is difficult.</h1>
      <h1 style={{ ...h1Style, marginBottom: "3rem" }}>
        get the help you need at <span style={{ color: "#689EF0" }}>kazarma.io</span>
      </h1>
      <div style={ctaButtonsStyle}>
        <button
          style={{
            ...buttonStyle,
            backgroundColor: "#FF9C00",
            color: "#fff",
          }}
          onClick={() => handleButtonClick("/engineering-tutoring-services")}
        >
          Get started with tutoring!
        </button>
        <button
          style={{ ...buttonStyle, backgroundColor: "#235196", color: "#fff" }}
          onClick={() => handleButtonClick("/engineering-college-prep-and-advising")}
        >
          Get started with college advising!
        </button>
      </div>
    </header>
  );
}

export default MainBanner;