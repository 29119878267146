import React from 'react';
import MainBanner from '../components/MainBanner';
import ServicesSection from '../components/home/ServicesSection';
import EventsSection from '../components/home/EventsSection';

// Main App Component
function Home() {
  return (
    <div className="Home">
      <MainBanner />
      <ServicesSection />
      <EventsSection />
    </div>
  );
}

export default Home;