
export const disciplines = [
    { value: "Aerospace Engineering", label: "Aerospace Engineering" },
    { value: "Bioengineering", label: "Bioengineering" },
    { value: "Chemical Engineering", label: "Chemical Engineering" },
    { value: "Civil Engineering", label: "Civil Engineering" },
    { value: "Computer Engineering", label: "Computer Engineering" },
    { value: "Data Science", label: "Data Science" },
    { value: "Electrical Engineering & Computer Sciences", label: "Electrical Engineering & Computer Sciences" },
    { value: "Engineering Economics & Management", label: "Engineering Economics & Management" },
    { value: "Engineering Geoscience", label: "Engineering Geoscience" },
    { value: "Engineering Mathematics & Statistics", label: "Engineering Mathematics & Statistics" },
    { value: "Engineering Physics", label: "Engineering Physics" },
    { value: "Environmental Engineering Science", label: "Environmental Engineering Science" },
    { value: "Industrial Engineering & Operations Research", label: "Industrial Engineering & Operations Research" },
    { value: "Materials Science & Engineering", label: "Materials Science & Engineering" },
    { value: "Mechanical Engineering", label: "Mechanical Engineering" },
    { value: "Nuclear Engineering", label: "Nuclear Engineering" },
    { value: "Undeclared Engineering", label: "Undeclared Engineering" },
  ];

  export const engineeringMajors = [
    "N/A",
    "Aerospace Engineering",
    "Bioengineering",
    "Chemical Engineering",
    "Civil Engineering",
    "Computer Engineering",
    "Data Science",
    "Electrical Engineering & Computer Sciences",
    "Engineering Economics & Management",
    "Engineering Geoscience",
    "Engineering Mathematics & Statistics",
    "Engineering Physics",
    "Environmental Engineering Science",
    "Industrial Engineering & Operations Research",
    "Materials Science & Engineering",
    "Mechanical Engineering",
    "Nuclear Engineering",
    "Undeclared Engineering"
];