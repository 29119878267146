import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router-dom';
import LeftArrow from '../images/left-arrow.png';

export default function TutorApplySubmitted() {
    const navigate = useNavigate();
    const handleButtonClick = (path) => {
        navigate(path)
    }
    return (
        <Stack gap={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', fontSize: '25px', textAlign: 'center', lineHeight: '2' }}>
            <div className="p-2"> Thank you for submitting your application to be a tutor! <br />
                We will send you an email once we have reviewed your information for next steps.
            </div>
            <div className="p-2"> <button type="button" class="btn" style={{ backgroundColor: "#F0EDED", color: "#000000" }}
            onClick={() => handleButtonClick("/home")}>
                <img src={LeftArrow} style={{ height: '20px' }}/>
                &nbsp; Return to homepage</button> </div>
        </Stack>
    )
}