import TutorTile from "../../components/student-select-tutors/TutorTile";
import AlessiaProfile from "../../images/alessia-profile.png"
import Select from 'react-select';

export default function TutorRequestPage3({ submitForm, handlePreviousPage, formData, setFormData}) {
    const waitTimeOptions = [
        { value: 0.5, label: '30 mins' },
        { value: 1, label: '1 hour' },
        { value: 1.5, label: '1.5 hours' },
        { value: 2, label: '2 hours' },
        { value: 2.5, label: '2.5 hours' },
        { value: 3, label: '3 hours' },
        { value: 3.5, label: '3.5 hours' },
        { value: 4, label: '4 hours' },
        { value: 4.5, label: '4.5 hours' },
        { value: 5, label: '5 hours' },
    ]
    return (
        <form id="tutoring-request-form">
            <div class="container" >
                <div class="row">
                    <a class="back-button float-right" 
                    style={{ cursor: 'pointer', marginLeft: '-34px', marginTop: '30px'}}
                    onClick={() => handlePreviousPage()}>← Back</a>
                </div>
                <div class="row" style={{ fontSize: '35px', textAlign: 'left', marginLeft: '-50px', marginTop: '20px' }}>
                    <div class="col">
                        <div class="text-start"
                            > Details of tutoring request
                        </div>
                    </div>
                </div>
                <div class="row" style={{textAlign: 'left', marginLeft: '-50px', marginTop: '10px' }}>
                    <div class="col">
                        <div style={{fontSize: '20px'}}>
                            1. Wait time for (1 - First Choice)  <br></br>                        
                        </div>
                        <div style={{fontSize: '16px', marginTop: '10px'}}>
                            Once you submit this tutor request, a message will be sent to the tutors ranked with 1, 2, or 3. <br></br>  
                            If your (1 - First Choice) tutor accepts your request, a session will automatically be scheduled. <br></br>
                            But if your (2 - Second Choice) or (3 - Third Choice) responds first, how many hours would you like to wait for a response from your (1 - First Choice) before we automatically set up a session with your (2 - Second Choice) or (3 - Third Choice) ranked tutors? <br></br>
                        </div>
                        <div style={{width: '50%', marginTop: '10px'}}>
                        <Select options={waitTimeOptions}
                            name='wait_time_first_choice'
                            id='wait_time_first_choice'
                            onChange={(selectedWaitTime) => setFormData({ ...formData, wait_time_first_choice: selectedWaitTime })} />
                        </div>
                    </div>
                </div>
                <div class="row" style={{textAlign: 'left', marginLeft: '-50px', marginTop: '30px' }}>
                    <div class="col">
                        <div style={{fontSize: '20px'}}>
                            2. Cancellation Policy <br></br>                        
                        </div>
                        <div style={{fontSize: '16px', marginTop: '10px'}}>
                            If you cancel within 24 hours of your session, there will be a 50% cancellation fee that goes directly to the tutor to compensate for the cancellation. <br></br>
                            If you cancel within a week before your session, there is a 25% cancellation fee that goes directly to the tutor to compensate for the cancellation. <br></br>
                            If you cancel more than a week before your session, there is no cancellation fee. <br></br><br></br>
                            Please check the box below to indicate that you accept the cancellation policy. <br></br>
                        </div>
                        <input type="checkbox" name="accept_cancellation_policy" id='accept_cancellation_policy' style={{marginTop: '20px'}} required/>
                        <label htmlFor="checkbox" style={{marginLeft: '10px'}}>I accept the cancellation policy.</label>
                    </div>
                </div>
                <div class="row" style={{marginTop: '50px'}}>
                    <div class="col-1">
                        <button class="btn btn-success" style={{marginLeft: '1145px' , borderRadius: '0'}}
                            onClick={() => submitForm()}>Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}