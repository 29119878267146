import CalendarIcon from "../../images/calendar-icon.png"
import DollarIcon from "../../images/dollar.png"
import StarIcon from "../../images/star-icon.png"

export default function TutorTile(props) {
    const { name, rating, icon, num_students, hourly_rate } = props;
    return (
        <div className="current-request-tile"
            style={{
                width: "369px",
                height: "420px",
                backgroundColor: "#F0EDED",
                marginTop: "20px",
            }}>
            <div className="container">
                <div className="row" style={{ textAlign: 'center', marginTop: '10px' }}>
                    <div className="col" style={{ marginTop: '30px' }}>
                        <img src={icon} style={{ height: "75px", width: "75px", alignSelf: 'center' }} />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col">
                        <div style={{ marginTop: '5px', fontSize: '20px', textAlign: 'center' }}>{name}</div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col-1">
                        <img src={StarIcon} style={{ height: "31px", width: "33px", marginTop: '-5px', marginLeft: '130px' }} />
                    </div>
                    <div className="col-2" style={{ marginLeft: '135px' }}>
                        <div style={{ fontSize: '18px' }}>{rating}</div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col">
                        <div style={{ marginTop: '5px', fontSize: '18px', textAlign: 'center', color: '#847F7F' }}>{num_students} students</div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-1">
                        <img src={DollarIcon} style={{ height: '38px', width: '36px', marginLeft: '20px' }} />
                    </div>
                    <div className="col-6">
                        <div style={{ marginTop: '5px', fontSize: '20px', textAlign: 'center' }}>${hourly_rate} / hour</div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-1">
                        <img src={CalendarIcon} style={{ height: '38px', width: '36px', marginLeft: '20px' }} />
                    </div>
                    <div className="col-6" style={{ marginLeft: '35px' }}>
                        Select a timeslot
                    </div>
                </div>
                <div className="row" style={{ marginTop: '10px', marginLeft: '10px' }}>
                    <div className="col-3">
                        Rank:
                    </div>
                    <div className="col-6" style={{ marginLeft: '-30px' }}>
                        <select>
                            <option value="1">First Choice</option>
                            <option value="2">Second Choice</option>
                            <option value="3">Third Choice</option>
                            <option value="0">Do Not Request</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}