import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { disciplines } from '../../data/EngineeringDisciplines.js';
import subjectData from '../../data/Subjects.json';
import { useNavigate } from 'react-router-dom';
import { post } from 'aws-amplify/api';
import CalendarIcon from "../../images/calendar-icon.png"

export default function TutorRequestPage1({ handleNextPage }) {
    const [formData, setFormData] = useState({
        discipline: '',
        subject: '',
        description: '',
        file_1: '',
        file_2: '',
        file_3: '',
        ignore_calendar: true,
        date_time: ''
    });
    const navigate = useNavigate();
    const handleButtonClick = (path) => {
        navigate(path)
    }

    // useState hook to track the selected value
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);
    // useState hook to track the available subjects 
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        // Extract options for the Select component
        const options = Object.entries(subjectData).map(([key, value]) => ({
            value: key,
            label: value
        }));
        setSubjects([]);
        setSelectedDiscipline(null);
    }, []);

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        const updatedData = { ...formData };

        if (type === 'checkbox' || type === 'radio') {
            updatedData[name] = event.target.checked;
        } else {
            updatedData[name] = value;
        }
        setFormData(updatedData);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        const restOperation = post({
            apiName: 'TutoringRequestApi',
            path: '/tutoring-request',
            options: {
                body: formData
            }
        });
        const response = await restOperation.json();
        console.log(response);
        navigate('/student-select-tutors')
    }
    // lesson durations
    const durationOptions = [
        { value: 0.5, label: '30 mins' },
        { value: 1, label: '1 hour' },
        { value: 1.5, label: '1.5 hours' },
        { value: 2, label: '2 hours' },
        { value: 2.5, label: '2.5 hours' },
        { value: 3, label: '3 hours' },
        { value: 3.5, label: '3.5 hours' },
        { value: 4, label: '4 hours' },
        { value: 4.5, label: '4.5 hours' },
        { value: 5, label: '5 hours' },
    ]

    // function to handle discipline change
    const handleDisciplineChange = (newSelectedDiscipline) => {
        if (newSelectedDiscipline) {
            setSubjects(subjectData[newSelectedDiscipline.value]); // update subject list based on new discipline
        } else {
            setSubjects([]); // if no discipline is selected, set subject list to empty
        }
        setSelectedDiscipline(newSelectedDiscipline); // update the selected discipline
        setFormData({ ...formData, subject: '' }); // reset the subject
    }
    return (
        <div class="container">
            <div class="row" style={{ marginLeft: '120px' }}>
                <div class="col">
                    <div class="text-start"
                        style={{ fontSize: '45px', textAlign: 'left', marginLeft: '-110px', marginTop: '100px' }} >Tutoring Request</div>
                </div>
            </div>

            <form id="tutoring-request-form" onSubmit={handleSubmit} style={{ marginTop: '25px' }}>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="discipline">1. Discipline</label>
                            <Select options={disciplines} value={selectedDiscipline}
                                onChange={handleDisciplineChange} components={{ Option: components.Option }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }),
                                }}
                                id='discipline'
                                name='discipline'
                            />
                        </div>
                        <div class="form-group" style={{ 'marginTop': '15px' }}>
                            <label for="subject">2. Subject</label>
                            <Select
                                options={subjects.map((subject) => ({ value: subject, label: subject }))}
                                value={formData.subject}
                                onChange={(selectedSubject) => setFormData({ ...formData, subject: selectedSubject })}
                                id='subject'
                                name='subject'
                            />
                        </div>

                        <div class="form-group" style={{ 'marginTop': '15px' }}>
                            <label for="subject">3. Briefly describe what you need help with</label>
                            <textarea class="form-control"
                                id="description"
                                name="description"
                                rows="3"
                                onChange={(text) => setFormData({ ...formData, description: text.target.value })}
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="row">
                            <label for="file_1">4. Upload relevant files</label>
                            <div class="col-4">
                                <div class="form-group d-flex align-items-center">
                                    <label style={{ fontSize: '12px', marginTop: '-15px' }}>&nbsp; 1.</label>
                                    <input type="file" id="file_1" name="file_1" style={{ fontSize: '12px' }} onChange={(e) => setFormData({ ...formData, file_1: e.target.files[0] })} />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group d-flex align-items-center">
                                    <label style={{ fontSize: '12px', marginTop: '-15px' }}>&nbsp; 2.</label>
                                    <input type="file" id="file_2" name="file_2" style={{ fontSize: '12px' }} onChange={(e) => setFormData({ ...formData, file_2: e.target.files[0] })} />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group d-flex align-items-center">
                                    <label style={{ fontSize: '12px', marginTop: '-15px' }}>&nbsp; 3.</label>
                                    <input type="file" id="file_3" name="file_3" style={{ fontSize: '12px' }} onChange={(e) => setFormData({ ...formData, file_3: e.target.files[0] })} />
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style={{ marginTop: '15px' }}>
                            <div class="row">
                                <div class="col-9">
                                    <label for="subject">5. Time and Date</label> <br />
                                    <span class="form-label-subtext" style={{ 'fontSize': '14px' }}>Your availability will be taken from your Kazarma calendar,
                                        which will used in determining which tutors to match you with you. If your calendar is not updated, click on the calendar
                                        icon to update your availability before submitting your request. </span>
                                </div>
                                <div class="col-1">
                                    <div class="btn-group">
                                        {/* <img src={CalendarIcon} class="float-center" alt="Image" style={{width: "40px", height: "40px"}}/> */}
                                        <button type="button" class="btn btn-primary float-right" style={{fontSize: "11px", width: '100px', height: '80px', position: 'relative', marginTop: '20px'}}>
                                            <img src={CalendarIcon} style={{position: 'absolute', top: '35%', left: '50%', width: "40px", height: "40px", transform: "translate(-50%, -50%)"}}/>
                                            <span style={{ position: 'absolute', bottom: 5, width: '100%', left: 0, textAlign: 'center' }}>Update Calendar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style={{ marginTop: '15px' }}>
                            <label for="subject">6. Duration of Session</label> <br />
                            <span class="form-label-subtext" style={{ 'fontSize': '14px' }}>Select the requested duration of your tutoring session (given in 30 minute intervals). </span>
                            <Select options={durationOptions} value={formData.duration}
                                name='date_time'
                                id='date_time'
                                onChange={(selectedDuration) => setFormData({ ...formData, duration: selectedDuration })} />
                        </div>
                        <button class="btn btn-success" style={{ marginTop: '15px', marginLeft: '500px' }}
                            onClick={() => handleNextPage()}>Find tutors!
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}