import React, { useState } from "react";
import Bug from "../images/bug.png";
import { post } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import { useSelector, useDispatch } from "react-redux"; // Update for hooks
import { setUsername } from "../store"; // Import the action creator
import { US_UNIVERSITIES } from "../data/UniversitiesUS";
import { engineeringMajors } from "../data/EngineeringDisciplines";
import { areasOfFocus } from "../data/AreasOfFocus";

export default function StudentSignUp() {
  const username = useSelector((state) => state.username); // Get username from store
  const dispatch = useDispatch(); // Get dispatch function from store

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    pursuing_b: false,
    completed_b: false,
    pursuing_m: false,
    completed_m: false,
    pursuing_p: false,
    completed_p: false,
    school_b: "",
    school_m: "",
    school_p: "",
    major_b: "",
    major_m: "",
    major_p: "",
    focus_1: "",
    focus_2: "",
    focus_3: "",
    learning_style_1: false,
    learning_style_2: false,
    learning_style_3: false,
    learning_style_4: false,
    learning_style_5: false,
    learning_style_6: false,
    learning_style_7_fill: "",
    teaching_experience: false,
    tutoring_experience: false,
    experience_description: "",
    consent_to_record: false,
    email: "",
    confirm_email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const updatedData = { ...formData };

    if (type === "checkbox" || type === "radio") {
      updatedData[name] = event.target.checked;
    } else {
      updatedData[name] = value;
    }
    setFormData(updatedData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent default form submission

    if (!validate()) {
      return;
    }

    // Submit to API Gateway
    try {
      const restOperation = post({
        apiName: "StudentProfileApi",
        path: "/student",
        options: {
          body: formData,
        },
      });
      const response = await restOperation.response;
      console.log(response);
      navigate("/student-sign-up-confirmation");
    } catch (error) {
      console.log("POST call failed: ", error);
    }

    // Sign up with AWS Cognito
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: formData.email,
        password: formData.password,
        attributes: {
          email: formData.email,
        },
      });
    } catch (error) {
      console.log("error signing up:", error);
    }

    // Update store
    dispatch(setUsername(formData.email));
  };

  const validate = () => {
    if (formData.email != formData.confirm_email) {
      alert("Email addresses do not match.");
      return false;
    }

    if (formData.password.length < 8) {
      alert("Password must be at least 8 characters long.");
      return false;
    }

    if (formData.password != formData.confirm_password) {
      alert("Passwords do not match.");
      return false;
    }
    return true;
  };

  return (
    <form id="tutor-apply-form" onSubmit={handleSubmit} className="container">
      <h2 className="text-center mb-4" style={{ marginTop: "15px" }}>
        Student Sign Up
      </h2>
      <div className="form-group" style={{ marginTop: "15px" }}>
        <div className="row">
          <div className="col-md-4">
          <label for="first_name">
            <h4>1. First Name:</h4>
          </label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            name="first_name"
            onChange={handleChange}
            required
          />
          </div>
        </div>
        <label for="last_name">
          <h4>2. Last Name:</h4>
        </label>
        <input
          type="text"
          className="form-control"
          id="last_name"
          name="last_name"
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group" style={{ marginTop: "15px" }}>
        <label for="education-level">
          <h4>3. Education Level:</h4>
        </label>
        <div class="container">
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="pursuing_b"
                name="pursuing_b"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="pursuing_b"
                style={{ marginLeft: "10px" }}
              >
                Pursuing Bachelor's
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="completed_b"
                name="completed_b"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="completed_b"
                style={{ marginLeft: "10px" }}
              >
                Completed Bachelor's
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="pursuing_m"
                name="pursuing_m"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="pursuing_m"
                style={{ marginLeft: "10px" }}
              >
                Pursuing Master's
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="completed_m"
                name="completed_m"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="completed_m"
                style={{ marginLeft: "10px" }}
              >
                Completed Master's
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="pursuing_p"
                name="pursuing_p"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="pursuing_p"
                style={{ marginLeft: "10px" }}
              >
                Pursuing PhD
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="completed_p"
                name="completed_p"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="completed_p"
                style={{ marginLeft: "10px" }}
              >
                Completed PhD
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="form-group"
        style={{ marginTop: "15px", display: "flex", flexDirection: "column" }}
      >
        <label htmlFor="school">
          <h4>4. School(s)</h4>
        </label>
        <div className="row">
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              Bachelor's:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="school_b"
              id="school_b"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {US_UNIVERSITIES.map((university) => (
                <option key={university} value={university}>
                  {university}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              Master's:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="school_m"
              id="school_m"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {US_UNIVERSITIES.map((university) => (
                <option key={university} value={university}>
                  {university}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              PhD:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="school_p"
              id="school_p"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {US_UNIVERSITIES.map((university) => (
                <option key={university} value={university}>
                  {university}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: "15px" }}>
        <label for="school">
          <h4>5. Major(s)</h4>
        </label>
        <div className="row">
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              Bachelor's:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="major_b"
              id="major_b"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {engineeringMajors.map((major) => (
                <option key={major} value={major}>
                  {major}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              Master's:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="major_m"
              id="major_m"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {engineeringMajors.map((major) => (
                <option key={major} value={major}>
                  {major}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="col-md-1">
            <span className="input-group-addon" id="basic-addon1">
              PhD:
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="major_p"
              id="major_p"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {engineeringMajors.map((major) => (
                <option key={major} value={major}>
                  {major}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: "15px" }}>
        <label>
          <h4>6. Areas of Focus</h4>
        </label>
        <div class="form-text-muted">
          Select up to three areas of focus. If you don't have any areas of
          focus yet because you are at the beginning of undergraduate, leave
          these blank.
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="custom-col" style={{width: '20px'}}>
            <span className="input-group-addon" id="basic-addon1">
              1.
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="focus_1"
              id="focus_1"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {areasOfFocus.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="custom-col" style={{width: '20px'}}>
            <span className="input-group-addon" id="basic-addon1">
                2.
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="focus_2"
              id="focus_2"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {areasOfFocus.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{marginTop: '5px'}}>
          <div className="custom-col" style={{width: '20px'}}>
            <span className="input-group-addon" id="basic-addon1">
              3.
            </span>
          </div>
          <div className="col-md-3">
            <select
              name="focus_3"
              id="focus_3"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            >
              {areasOfFocus.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: "15px" }}>
        <label>
          <h4>7. What is your learning style?</h4>
        </label>
        <div class="container">
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_1"
                name="learning_style_1"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_1"
                style={{ marginLeft: "10px" }}
              >
                Diagrams / visualizations
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_4"
                name="learning_style_4"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_4"
                style={{ marginLeft: "10px" }}
              >
                Mini-lectures
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_2"
                name="learning_style_2"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_2"
                style={{ marginLeft: "10px" }}
              >
                Working through practice problems
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_5"
                name="learning_style_5"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_5"
                style={{ marginLeft: "10px" }}
              >
                Verbal explanations
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_3"
                name="learning_style_3"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_3"
                style={{ marginLeft: "10px" }}
              >
                Going through textbooks
              </label>
            </div>
            <div class="col-sm-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="learning_style_6"
                name="learning_style_6"
                onChange={handleChange}
              />
              <label
                class="form-check-label"
                for="learning_style_6"
                style={{ marginLeft: "10px" }}
              >
                Proofs, derivations
              </label>
            </div>
          </div>
          <div class="input-group">
            <span
              class="input-group-addon"
              id="basic-addon1"
              style={{ marginTop: "8px" }}
            >
              Another teaching method, please give a 1-4 word description:
            </span>
            <input
              type="text"
              className="form-control"
              id="learning_style_7_fill"
              name="learning_style_7_fill"
              onChange={handleChange}
              style={{ marginTop: "5px", marginLeft: "3px" }}
            />
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: "15px" }}>
        <label>
          <h4>8. Email</h4>
        </label>
        <div class="container">
          <div class="row">
            <div class="col-md-4 ">
              <div class="row">
                <label for="email">Email for tutoring notifications:</label>
              </div>
              <div class="row">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  style={{ marginTop: "5px", marginLeft: "10px" }}
                  required
                />
              </div>
              <div class="row">
                <label for="email">Confirm email:</label>
              </div>
              <div class="row">
                <input
                  type="text"
                  className="form-control"
                  id="confirm_email"
                  name="confirm_email"
                  onChange={handleChange}
                  style={{ marginTop: "5px", marginLeft: "10px" }}
                  required
                />
              </div>
            </div>
            <div
              class="col-md-4"
              style={{ marginTop: "10px", marginLeft: "30px" }}
            >
              Important Notice: Your contact information (email, phone number
              etc.) will ONLY be used for communication with Kazarma, such as
              notifications for payment and student tutoring requests. All your
              personal information will be kept within Kazarma and will not be
              used for any other purpose.
            </div>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ marginTop: "15px" }}>
        <label>
          <h4>9. Phone number</h4>
        </label>
        <div class="container">
          <div class="row">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              onChange={handleChange}
              style={{ marginTop: "5px", marginLeft: "10px" }}
            />
          </div>
        </div>
      </div>

      <div
        className="form-group"
        style={{ marginTop: "15px", marginBottom: "100px" }}
      >
        <label>
          <h4>10. Password</h4>
        </label>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <label for="password">Password for account:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                onChange={handleChange}
                style={{ marginTop: "5px" }}
                required
              />
              <label for="confirm_password">Confirm password:</label>
              <input
                type="password"
                className="form-control"
                id="confirm_password"
                name="confirm_password"
                onChange={handleChange}
                style={{ marginTop: "5px" }}
                required
              />
            </div>
            <div class="col-md-1">
              <button
                class="btn btn-primary btn-block"
                style={{
                  height: "40px",
                  width: "80px",
                  marginLeft: "410px",
                  marginTop: "100px",
                }}
              >
                <img
                  src={Bug}
                  style={{
                    verticalAlign: "middle",
                    marginTop: "-10px",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <p style={{ fontSize: "8px", marginTop: "-5px" }}>
                  Report bugs
                </p>
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="submit"
                className="btn btn-success btn-block"
                style={{ marginLeft: "400px", marginTop: "100px" }}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
