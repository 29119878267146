import React from "react";
import advisingImage from "../../images/home-page-image-1.png";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch', 
  marginBottom: "2rem",
};
const tutoringServicesStyle = {
  backgroundColor: "#fff",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  padding: "1rem",
  borderRadius: "8px",
  width: "100%", // Ensures it takes the full width of its parent
  height: "100%", // Ensures it takes the full height of its parent
  display: 'flex', // Sets the display to flex to use Flexbox properties
  flexDirection: 'column', // Children are stacked vertically
  justifyContent: 'center', // Centers children vertically within the container
};

const imageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', // This centers the image vertically
  width: '100%', // This ensures the container takes full width
};

const imageStyle = {
  maxWidth: "100%", // Ensures the image is responsive
  height: "auto", // Ensures the image is responsive
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap", // Allows buttons to wrap on smaller screens
};

const buttonStyle = {
  flex: "1",
  padding: "0.5rem 1rem",
  margin: "0.5rem",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};


function TutoringServices() {
  const navigate = useNavigate();
  const handleButtonClick = (path) => {
    navigate(path);
  };
  return (
    <div className="row" style={containerStyle}>
      <div className="col-12 col-md-3">
        <div style={imageContainerStyle}>
          <img
            src={advisingImage}
            alt="Advising Image"
            style={imageStyle}
          />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div style={tutoringServicesStyle}>
          <h3 style={{ marginBottom: "20px" }}>
            University Level Engineering Tutoring Services
          </h3>
          <p>
          At Kazarma, we understand the challenges that come
          with pursuing engineering.           
          <br></br>
          <br></br>
          That's why we offer specialized tutoring services designed to help you excel.
          <br></br>
          <br></br>
          Our team of experienced tutors is well-versed in a vast array of
          engineering disciplines, ensuring that you receive the support and
          guidance needed for any subject.
          <br></br>
          <br></br>
          Whether you're grappling with thermodynamics, circuit
          design, or data engineering - our
          personalized tutoring approach is tailored to fit your unique needs.
          <br></br>
          <br></br>
          <b>Get started with Kazarma today and find a tutor who can help you!</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TutoringServices;
