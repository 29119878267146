import React, { useState } from 'react';

function StudentReviewTutor() {
  const [rating, setRating] = useState(4); // assuming the default rating is 4 stars
  const [feedback, setFeedback] = useState({
    positive: '',
    improvement: '',
    notes: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the submission logic, like sending the data to a backend server
    console.log('Submitted Rating:', rating);
    console.log('Submitted Feedback:', feedback);
  };

  // Inline CSS styles
  const styles = {
    formContainer: {
      maxWidth: '600px',
      margin: '2rem auto',
      padding: '2rem',
      backgroundColor: '#f9f9f9',
      border: '1px solid #e1e1e1',
      borderRadius: '8px',
    },
    heading: {
      color: '#333',
      textAlign: 'center',
      marginBottom: '1.5rem',
    },
    formGroup: {
      marginBottom: '1rem',
    },
    label: {
      display: 'block',
      marginBottom: '.5rem',
      color: '#333',
    },
    rating: {
      color: '#ffd700',
      cursor: 'pointer',
      fontSize: '1.5rem',
    },
    star: {
      padding: '0 5px',
    },
    textarea: {
      width: '100%',
      padding: '0.5rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginTop: '0.25rem',
    },
    submitButton: {
      display: 'block',
      width: '100%',
      padding: '1rem',
      marginTop: '1rem',
      backgroundColor: '#0056b3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    }
  };

  return (
    <div style={styles.formContainer}>
      <h2 style={styles.heading}>Review your tutor, Ben</h2>
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            How would you rate Ben overall as a tutor?
            <div style={styles.rating}>
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  onClick={() => setRating(index + 1)}
                  style={styles.star}
                >
                  {index < rating ? '★' : '☆'}
                </span>
              ))}
            </div>
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            What did Ben do well?
            <textarea
              style={styles.textarea}
              value={feedback.positive}
              onChange={(e) => setFeedback({ ...feedback, positive: e.target.value })}
            />
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            What could Ben improve on?
            <textarea
              style={styles.textarea}
              value={feedback.improvement}
              onChange={(e) => setFeedback({ ...feedback, improvement: e.target.value })}
            />
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            Was there anything in particular you wanted to note about this session?
            <textarea
              style={styles.textarea}
              value={feedback.notes}
              onChange={(e) => setFeedback({ ...feedback, notes: e.target.value })}
            />
          </label>
        </div>
        <button type="submit" style={styles.submitButton}>Submit Review</button>
      </form>
    </div>
  );
}

export default StudentReviewTutor;
