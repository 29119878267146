import { useState } from 'react';
import TutorRequestPage1 from './student-create-request/TutorRequestPage1';
import TutorRequestPage2 from './student-create-request/TutorRequestPage2';
import TutorRequestPage3 from './student-create-request/TutorRequestPage3';

export default function StudentCreateRequest() {
    const [currentPage, setCurrentPage] = useState(1);

    const [formData, setFormData] = useState({
        discipline: '',
        subject: '',
        description: '',
        file_1: '',
        file_2: '',
        file_3: '',
        ignore_calendar: true,
        date_time: '',
        duration: '',
        possible_tutor_1: '',
        rank_tutor_1: '',
        time_tutor_1: '',
        possible_tutor_2: '',
        rank_tutor_2: '',
        time_tutor_2: '',
        possible_tutor_3: '',
        rank_tutor_3: '',
        time_tutor_3: '',
        wait_time_first_choice: '',
        accept_cancellation_policy: false,
    });

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const submitForm = () => {
    
    }

    return (
        <div>
            {currentPage === 1 && <TutorRequestPage1 handleNextPage={handleNextPage} />}
            {currentPage === 2 && <TutorRequestPage2 handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage}/>}
            {currentPage === 3 && <TutorRequestPage3 formData={formData} setFormData={setFormData} handlePreviousPage={handlePreviousPage} submit={submitForm}/>}
        </div>
    );
}