import React from 'react';

const styles = {
    upcomingTalks: {
        marginTop: '40px',
        padding: '20px',
        backgroundColor: '#F0EDED',
        borderRadius: '8px',
        textAlign: 'center',
    },
    talksContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    talkHeader: {
        fontSize: '28px',
        marginBottom: '20px',
        color: '#333',
    },
    talkCard: {
        margin: '20px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '45%',
        textAlign: 'left',
        boxSizing: 'border-box',
    },
    talkTitle: {
        fontSize: '22px',
        marginBottom: '10px',
    },
    talkDescription: {
        fontSize: '16px',
        color: '#555',
    },
    signUpButton: {
        display: 'inline-block',
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#007BFF',
        borderRadius: '5px',
        textDecoration: 'none',
        textAlign: 'center',
    },
};

const EventsSection = () => {
    return (
        <div style={styles.upcomingTalks}>
            <div style={styles.talkHeader}>Upcoming Events by Kazarma</div>
            <div style={styles.talksContainer}>
                <div style={styles.talkCard}>
                    <div style={styles.talkTitle}>"Becoming an Engineer" Talk</div>
                    <p style={styles.talkDescription}>
                        📅 Date: Tuesday, June 11th <br />
                        ⏰ Time: 6:30 PM Pacific Time Zone (PT)<br />
                        🏡 Location: Online <br /><br />
                        Becoming an engineer is difficult. Join Alessia Levante, a recent graduate from UC Berkeley’s College of Engineering, for an engaging talk specifically for high school students who are interested in studying engineering or the applied sciences at college.
                        <br /><br />
                        In this talk, Alessia will discuss her personal journey and insights to becoming an engineer, and the vast array of engineering disciplines that you can study, to help start your higher education journey on the right path.
                    </p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfkZ9MPj8HZ1ZQPO9-Pp3jguvSTz_NrVW-LELkeT0nlc9hvoQ/viewform" style={styles.signUpButton} target="_blank" rel="noopener noreferrer">
                        Sign Up
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EventsSection;
