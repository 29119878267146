import React, { useState } from "react";
import { signIn } from "aws-amplify/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function StudentLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [error, setError] = useState(null); // State for storing error message

  const handleLogin = async () => {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      navigate('/student-home'); // Navigate to the home page
    } catch (error) {
      console.log("error signing in", error);
      setError('Invalid username or password. Please try again.'); // Set error message
    }
  };

  const handleForgotPassword = () => {
    // Add logic to handle the "Forgot Password" functionality
    console.log("Forgot Password clicked");
  };

  const containerStyle = {
    width: '350px', // Increase the width to make it larger
    margin: '10px auto 0',
    padding: '30px', // Increase the padding to adjust the box size
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
  };  

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "3px",
  };

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <h2>Login</h2>
      <div className="login-form">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          style={inputStyle}
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={inputStyle}
        />
        
        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message if exists */}

        <button onClick={handleLogin} style={buttonStyle}>
          Login
        </button>

        <p style={{ textAlign: "center", margin: '10px'}}>
          <a href="#" onClick={handleForgotPassword}>
            Forgot Password?
          </a>
        </p>
      </div>
    </div>
  );
}

export default StudentLogin;
