import React, { useState } from 'react';
import Bug from '../images/bug.png';
import { post } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';

export default function TutorApply() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    pursuing_b: false,
    completed_b: false,
    pursuing_m: false,
    completed_m: false,
    pursuing_p: false,
    completed_p: false,
    school_b: '',
    school_m: '',
    school_p: '',
    major_b: '',
    major_m: '',
    major_p: '',
    focus_1: '',
    focus_2: '',
    focus_3: '',
    subject_1: '',
    subject_1_min_price: '0',
    subject_2: '',
    subject_2_min_price: '0',
    subject_3: '',
    subject_3_min_price: '0',
    subject_4: '',
    subject_4_min_price: '0',
    subject_5: '',
    subject_5_min_price: '0',
    learning_style_1: false,
    learning_style_2: false,
    learning_style_3: false,
    learning_style_4: false,
    learning_style_5: false,
    learning_style_6: false,
    learning_style_7_fill: '',
    teaching_experience: false,
    tutoring_experience: false,
    experience_description: '',
    consent_to_record: false,
    email: '',
    confirm_email: '',
    phone: '',
    password: '',
    confirm_password: ''
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const updatedData = { ...formData};

    if (type === 'checkbox' || type === 'radio') {
        updatedData[name] = event.target.checked;
    } else {
        updatedData[name] = value;
    }
    setFormData(updatedData);
  }

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent default form submission

    if (!validate()) {
      return;
    }

    try {
      const restOperation = post({
        apiName: 'TutorProfileApi',
        path: '/tutor',
        options: {
          body: formData
        }
      });
      const response = await restOperation.response;
      console.log(response) 
      navigate('/tutor-apply-submitted');
    } catch (error) {
      console.log('POST call failed: ', error);
    }
  }

  const validate = () => {
    if (formData.email != formData.confirm_email) {
        alert("Email addresses do not match.")
        return false;
    }

    if (formData.password.length < 8) {
        alert("Password must be at least 8 characters long.")
        return false;
    }

    if (formData.password != formData.confirm_password) {
        alert("Passwords do not match.")
        return false;
    }
    return true;
}

  return (
      <form id="tutor-apply-form" onSubmit={handleSubmit} className="container">
        <h2 className="text-center mb-4" style={{ marginTop: '15px' }}>Tutor Application</h2>
        <div className="form-group" style={{ marginTop: '15px' }}>
          <label for="first_name"><h4>1. First Name:</h4></label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            name="first_name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group" style={{ marginTop: '15px' }}>
          <label for="last_name"><h4>2. Last Name:</h4></label>
          <input
            type="text"
            className="form-control"
            id="last_name"
            name="last_name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group" style={{ marginTop: '15px' }}>
          <label for="education-level"><h4>3. Education Level:</h4></label>
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="pursuing_b" name="pursuing_b" onChange={handleChange}/>
                <label class="form-check-label" for="pursuing_b" style={{ marginLeft: '10px' }}>Pursuing Bachelor's</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="completed_b" name="completed_b" onChange={handleChange}/>
                <label class="form-check-label" for="completed_b" style={{ marginLeft: '10px' }}>Completed Bachelor's</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="pursuing_m" name="pursuing_m" onChange={handleChange}/>
                <label class="form-check-label" for="pursuing_m" style={{ marginLeft: '10px' }}>Pursuing Master's</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="completed_m" name="completed_m" onChange={handleChange}/>
                <label class="form-check-label" for="completed_m" style={{ marginLeft: '10px' }}>Completed Master's</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="pursuing_p" name="pursuing_p" onChange={handleChange}/>
                <label class="form-check-label" for="pursuing_p" style={{ marginLeft: '10px' }}>Pursuing PhD</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="completed_p" name="completed_p" onChange={handleChange}/>
                <label class="form-check-label" for="completed_p" style={{ marginLeft: '10px' }}>Completed PhD</label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label for="school"><h4>4. School(s)</h4></label>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Bachelor's: </span>
            <input type="text" className="form-control" id="school_b" name="school_b" onChange={handleChange} style={{ marginLeft: '5px' }} />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Master's: </span>
            <input type="text" className="form-control" id="school_m" name="school_m" onChange={handleChange} style={{ marginLeft: '19px' }} />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>PhD: </span>
            <input type="text" className="form-control" id="school_p" name="school_p" onChange={handleChange} style={{ marginLeft: '51px' }} />
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label for="school"><h4>5. Major(s)</h4></label>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Bachelor's: </span>
            <input type="text" className="form-control" id="major_b" name="major_b" onChange={handleChange} style={{ marginLeft: '5px' }} />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Master's: </span>
            <input type="text" className="form-control" id="major_mr" name="major_m" onChange={handleChange} style={{ marginLeft: '19px' }} />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>PhD: </span>
            <input type="text" className="form-control" id="major_p" name="major_p" onChange={handleChange} style={{ marginLeft: '51px' }} />
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>6. Areas of Focus</h4></label>
          <div class="form-text-muted">Select up to three areas of focus. If you don't have any areas of focus yet because you are at the beginning of undergraduate, leave these blank.</div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>1.</span>
            <input type="text" className="form-control" id="focus_1" name="focus_1" onChange={handleChange} style={{ marginLeft: '5px' }} required />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>2.</span>
            <input type="text" className="form-control" id="focus_2" name="focus_2" onChange={handleChange} style={{ marginLeft: '3px' }} />
          </div>
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>3.</span>
            <input type="text" className="form-control" id="focus_3" name="focus_3" onChange={handleChange} style={{ marginLeft: '3px' }} />
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>7. Proposed subjects to tutor</h4></label>
          <div class="form-text-muted">The requirements are that you receive an A- or higher in each class that you tutor or have worked in a relevant industry for at least 1 year. We will be requiring proof.</div>
          <div class="input-group">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>1.</span>
                  <input type="text" className="form-control" id="subject_1" name="subject_1" onChange={handleChange} style={{ marginLeft: '7.5px' }} required />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Minimum Rate [$/hour]</span>
                  <input type="text" className="form-control" id="subject_1_min_price" name="subject_1_min_price" onChange={handleChange} style={{ marginLeft: '5px' }} required />
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>2.</span>
                  <input type="text" className="form-control" id="subject_2" name="subject_2" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Minimum Rate [$/hour]</span>
                  <input type="text" className="form-control" id="subject_2_min_price" name="subject_2_min_price" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>3.</span>
                  <input type="text" className="form-control" id="subject_3" name="subject_3" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Minimum Rate [$/hour]</span>
                  <input type="text" className="form-control" id="subject_3_min_price" name="subject_3_min_price" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>4.</span>
                  <input type="text" className="form-control" id="subject_4" name="subject_4" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Minimum Rate [$/hour]</span>
                  <input type="text" className="form-control" id="subject_4_min_price" name="subject_4_min_price" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>5.</span>
                  <input type="text" className="form-control" id="subject_5" name="subject_5" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '3px' }}>Minimum Rate [$/hour]</span>
                  <input type="text" className="form-control" id="subject_5_min_price" name="subject_5_min_price" onChange={handleChange} style={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>8. Preferred methods of teaching</h4></label>
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_1" name="learning_style_1" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_1" style={{ marginLeft: '10px' }}>Diagrams / visualizations</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_4" name="learning_style_4" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_4" style={{ marginLeft: '10px' }}>Mini-lectures</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_2" name="learning_style_2" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_2" style={{ marginLeft: '10px' }}>Working through practice problems</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_5" name="learning_style_5" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_5" style={{ marginLeft: '10px' }}>Verbal explanations</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_3" name="learning_style_3" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_3" style={{ marginLeft: '10px' }}>Going through textbooks</label>
              </div>
              <div class="col-sm-3">
                <input class="form-check-input" type="checkbox" id="learning_style_6" name="learning_style_6" onChange={handleChange}/>
                <label class="form-check-label" for="learning_style_6" style={{ marginLeft: '10px' }}>Proofs, derivations</label>
              </div>
            </div>
            <div class="input-group">
              <span class="input-group-addon" id="basic-addon1" style={{ marginTop: '8px' }}>Another teaching method, please give a 1-4 word description:</span>
              <input type="text" className="form-control" id="learning_style_7_fill" name="learning_style_7_fill" onChange={handleChange} style={{ marginTop: '5px', marginLeft: '3px' }} />
            </div>
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>9. Do you have teaching or tutoring experience?</h4></label>
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <input class="form-check-input" type="checkbox" id="teaching_experience" name="teaching_experience" onChange={handleChange}/>
                <label class="form-check-label" for="teaching_experience" style={{ marginLeft: '10px' }}>Teaching (e.g. being an employed teacher or teacher’s assistant)</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <input class="form-check-input" type="checkbox" id="tutoring_experience" name="tutoring_experience" onChange={handleChange}/>
                <label class="form-check-label" for="tutoring_experience" style={{ marginLeft: '10px' }}>Tutoring (e.g.tutoring one-on-one privately or for a company)</label>
              </div>
            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <label for="experience_description" style={{ marginLeft: '10px' }}>If you have some past experience, please give a brief description:</label>
            </div>
            <div class="row">
              <input type="text" className="form-control" id="experience_description" name="experience_description" onChange={handleChange} style={{ marginTop: '5px', marginLeft: '3px' }} />
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>10. Permission to record?</h4></label>
          <div class="container">
            <div class="row" style={{ marginTop: '10px' }}>
              <label class="form-check-label" style={{ marginTop: '-10px' }}>Some students benefit from recording tutoring sessions and may request to have a session recorded. These recordings will be kept for 1 month.
                Only the student and Kazarma staff with have access to the recording. Do you give consent to be recorded?
                If you do not mark yes, you will not be recommended for students who request recorded tutoring session. </label>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <input class="form-check-input" type="checkbox" id="consent_to_record" name="consent_to_record" onChange={handleChange}/>
                <label class="form-check-label" for="consent_to_record" style={{ marginLeft: '10px' }}>By checking this box, I consent to being recorded for tutoring</label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>11. Email</h4></label>
          <div class="container">
            <div class="row">
              <div class="col-md-4 ">
                <div class="row">
                  <label for="email">Email for tutoring notifications:</label>
                </div>
                <div class="row">
                  <input type="text" className="form-control" id="email" name="email" onChange={handleChange} style={{ marginTop: '5px', marginLeft: '10px' }} required />
                </div>
                <div class="row">
                  <label for="email">Confirm email:</label>
                </div>
                <div class="row">
                  <input type="text" className="form-control" id="confirm_email" name="confirm_email" onChange={handleChange} style={{ marginTop: '5px', marginLeft: '10px' }} required />
                </div>
              </div>
              <div class="col-md-4" style={{ marginTop: '10px', marginLeft: '30px' }}>
                Important Notice: Your contact information (email, phone number etc.) will ONLY be used for communication with Kazarma, such as notifications for payment and student tutoring requests. All your personal information will be kept within Kazarma and will not be used for any other purpose.
              </div>
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px' }}>
          <label><h4>12. Phone number</h4></label>
          <div class="container">
            <div class="row">
              <input type="text" className="form-control" id="phone" name="phone" onChange={handleChange} style={{ marginTop: '5px', marginLeft: '10px' }} />
            </div>
          </div>
        </div>

        <div className="form-group" style={{ marginTop: '15px', marginBottom: '100px' }}>
          <label><h4>13. Password</h4></label>
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <label for="password">Password for account:</label>
                <input type="password" className="form-control" id="password" name="password" onChange={handleChange} style={{ marginTop: '5px' }} required />
                <label for="confirm_password">Confirm password:</label>
                <input type="password" className="form-control" id="confirm_password" name="confirm_password" onChange={handleChange} style={{ marginTop: '5px' }} required />
              </div>
              <div class="col-md-1">
                <button class="btn btn-primary btn-block" style={{ height: '40px', width: '80px', marginLeft: '410px', marginTop: '100px' }}>
                  <img src={Bug} style={{ verticalAlign: 'middle', marginTop: '-10px', height: '20px', width: '20px' }} />
                  <p style={{ fontSize: '8px', marginTop: '-5px' }}>Report bugs</p>
                </button>
              </div>
              <div class="col-md-6">
                <button type="submit" className="btn btn-success btn-block" style={{ marginLeft: '400px', marginTop: '100px' }}>
                  Submit Tutor Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </form >
    );
  }