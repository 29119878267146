import React from 'react';
import CollegeAdvisingOption from './CollegeAdvisingOption';
import AcceptedUniversities from '../../images/colleges-accepted.png';
import Services from './Services';

const styles = {
  container: {
    fontFamily: 'Roboto, sans-serif',
    marginTop: '20px',
  },
  header: {
    marginTop: '30px',
    textAlign: 'center',
    fontSize: '35px',
  },
  subHeader: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '15px',
    color: '#847F7F',
  },
  packageContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  package: {
    border: '1px solid #DDD',
    padding: '15px',
    width: '30%',
  },
  packageTitle: {
    backgroundColor: '#EFEFEF',
    padding: '5px',
    fontWeight: 'bold',
  },
  list: {
    paddingLeft: '20px',
  },
  universitiesContainer: {
    textAlign: 'center',
    padding: '20px',
    color: '#847F7F',
  },
  universityLogo: {
    margin: '5px',
    width: '90vw'
  },
  callToAction: {
    textAlign: 'center',
    fontSize: '18px',
    width: '80%',
  },
  scheduleButton: {
    backgroundColor: '#235196',
    color: '#fff',
    padding: '10px 20px',
    textDecoration: 'none',
    display: 'inline-block',
    fontFamily: 'Roboto, sans-serif',
    marginLeft: '20px',
  },
  topCard: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "#fff",
  },
  serviceInformation: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  upcomingTalks: {
    marginTop: '40px',
    padding: '20px',
    backgroundColor: '#F0EDED',
    borderRadius: '8px',
    textAlign: 'center',
  },
  talksContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  talkHeader: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#333',
  },
  talkCard: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '45%',
    textAlign: 'left',
    boxSizing: 'border-box',
  },
  talkTitle: {
    fontSize: '22px',
    marginBottom: '10px',
    color: '#235196',
  },
  talkDescription: {
    fontSize: '16px',
    color: '#555',
  },
  link: {
    color: '#235196',
    textDecoration: 'none',
  }
};

// college advising aid package
const collegeAdvisingAidHeader = "College Advising Aid Package";
const collegeAdvisingContent = "This 10 week package is perfect for students who have started the college application process \
but are seeking expert advice to elevate their application to the next level.\
"
const collegeAdvisingAidItems = [
  "Engineering Major Choice Selection",
  "Essay Writing Support",
  "Application Planning",
  "Mock Interviews"
];

// college advising full package
const collegeAdvisingFullPackageHeader = "College Advising Full Package";
const collegeAdvisingFullPackageContent = "This is a 20 week package that offers extensive support, \
  guiding students through every step of the college admissions journey.";
const collegeAdvisingFullPackageItems = [
  "Engineering Major Choice Selection",
  "Personalized College Selection",
  "Essay Writing Support",
  "Extracurricular Advising",
  "Application Planning",
  "Mock Interviews"
]

// HS to College Package
const hsToCollegeFullPackageHeader = "HS to College Full Package";
const hsToCollegeFullPackageContent = "This annual package is ideal for high school students who are beginning to think about \
college and want to ensure they are on the path to successfully apply for engineering programs.";
const hsToCollegeFullPackageItems = [
  "General Academic Planning",
  "Extracurricular Advising",
  "High School Class Selection Advice"
];

// Ad hoc College Advising
const adHocCollegeAdvisingHeader = "Ad Hoc College Advising";
const adHocCollegeAdvisingContent = "This package is ideal for high school or community college students who want ad hoc advising about applying to college for engineering.";
const adHocCollegeAdvisingItems = [
  "General Academic Planning",
  "Advising on Engineering Major Choice Selection",
  "Extracurricular Advising",
  "College Application Planning"
]

const EngineeringCollegePrepAndAdvising = () => (
  <div style={styles.container}>
    <div style={styles.header}>Engineering College Prep and Advising</div>
    <div style={styles.subHeader}>engineering college advising for high school and community college students</div>
    <div style={styles.packageContainer}>
      <CollegeAdvisingOption header={collegeAdvisingAidHeader} content={collegeAdvisingContent} listItems={collegeAdvisingAidItems} />
      <CollegeAdvisingOption header={collegeAdvisingFullPackageHeader} content={collegeAdvisingFullPackageContent} listItems={collegeAdvisingFullPackageItems} backgroundColor={'#FF9C00'} textColor={'#FFF'} />
      <CollegeAdvisingOption header={hsToCollegeFullPackageHeader} content={hsToCollegeFullPackageContent} listItems={hsToCollegeFullPackageItems} />
      <CollegeAdvisingOption header={adHocCollegeAdvisingHeader} content={adHocCollegeAdvisingContent} listItems={adHocCollegeAdvisingItems} />
    </div>
    <div style={styles.topCard}>
      <div style={styles.callToAction}>
        <br></br>Interested in our college advising services?
        <br></br>Fill out our interest form and we will reach out to you for a consultation appointment.
        <br></br><br></br>
        <a href="https://pbikdiv1rm1.typeform.com/to/WPG9cufh" style={styles.scheduleButton}> Interest Form</a>
      </div>
    </div>
    <div style={styles.serviceInformation}>
      <Services />
    </div>
    <div style={styles.universitiesContainer}>
      <h3>Our students have been accepted to...</h3>
      <img src={AcceptedUniversities} style={styles.universityLogo} />
    </div>
  </div>
);

export default EngineeringCollegePrepAndAdvising;
