import TutorTile from "../../components/student-select-tutors/TutorTile";
import AlessiaProfile from "../../images/alessia-profile.png"

export default function TutorRequestPage2({ handleNextPage, handlePreviousPage }) {
    return (
        <form id="tutoring-request-form">
            <div class="container" >
                <div class="row">
                    <a class="back-button float-right" 
                    style={{ cursor: 'pointer', marginLeft: '-34px', marginTop: '30px'}}
                    onClick={() => handlePreviousPage()}>← Back</a>
                </div>
                <div class="row" style={{ fontSize: '35px', textAlign: 'left', marginLeft: '-50px', marginTop: '20px' }}>
                    <div class="col">
                        <div class="text-start"
                            > View and rank recommended tutors
                        </div>
                    </div>
                </div>
                <div class="row" style={{ fontSize: '18px', textAlign: 'left', marginLeft: '-50px', marginTop: '10px' }}>
                    <div class="col">
                        <div class="text-start">
                            1. View each tutor’s profile by clicking on their name.  <br></br>
                            2. Click on the calendar icon to select a time slot for the requested tutoring session, unless you do not want to request a tutor. <br></br>
                            3. Rank each tutor with one of the following: 1 (First Choice), 2 (Second Choice), 3 (Third Choice), or 0 (Do Not Request).  <br></br>
                            Note: If you have no preference between tutors, you can rank them with the same number. <br></br>
                        </div>
                    </div>
                </div>
                <div class="row" style={{ fontSize: '18px', textAlign: 'left', marginLeft: '-50px', marginTop: '10px' }}>
                    <div class="col">
                        <TutorTile name="Riddhi B." rating="4.8"  icon={AlessiaProfile} num_students="55" hourly_rate="85"/>
                    </div>
                    <div class="col">
                        <TutorTile name="Jeremy N." rating="4.6"  icon={AlessiaProfile} num_students="57" hourly_rate="70"/>
                    </div>
                    <div class="col">
                        <TutorTile name="Diana G." rating="4.3"  icon={AlessiaProfile} num_students="12" hourly_rate="60"/>
                    </div>
                </div>
                <div class="row" style={{marginTop: '50px'}}>
                    <div class="col-1">
                        <button class="btn btn-success" style={{marginLeft: '1145px' , borderRadius: '0'}}
                            onClick={() => handleNextPage()}>Continue
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}