export const US_UNIVERSITIES = [
    "N/A",
    "California Institute of Technology (Caltech)",
    "Carnegie Mellon University (CMU)",
    "Columbia University",
    "Cornell University",
    "Duke University",
    "Harvard University",
    "Johns Hopkins University",
    "Massachusetts Institute of Technology (MIT)",
    "New York University (NYU)",
    "Northwestern University",
    "Princeton University",
    "Stanford University",
    "University of California, Berkeley",
    "University of California, Los Angeles (UCLA)",
    "University of California, San Francisco",
    "University of Chicago",
    "University of Michigan, Ann Arbor",
    "University of Pennsylvania",
    "University of Texas at Austin",
    "University of Washington",
    "Yale University"
  ];