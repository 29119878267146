import React from 'react';

const styles = {
    container: {
        fontFamily: 'Roboto, sans-serif',
        padding: '20px',
        height: '80vh'
    },
    header: {
        marginTop: '10px',
        padding: '10px',
        textAlign: 'center',
        fontSize: '40px',
    },
    callToAction: {
        textAlign: 'center',
        marginLeft: '200px',
        marginRight: '200px'
      },
}

const AboutUs = () => (
    <div style={styles.container}>
        <div style={styles.header}>About Us</div> 
        <div style={styles.topCard}>
            <div style={styles.callToAction}>
            <br></br>Welcome to Kazarma! [Add in text]
            </div>
        </div>
    </div>
)

export default AboutUs;
