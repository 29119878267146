// import CurrentRequestTile from "../components/student-home/CurrentRequestTile"
// import UpcomingSessionTile from "../components/student-home/UpcomingSessionTile"
// import PastSessionTile from "../components/student-home/PastSessionTile"
// import AlessiaProfile from "../images/alessia-profile.png"
// import CalendarIcon from "../images/calendar-icon.png"
// import BirksProfile from "../images/birks-profile.png"
// import SettingsIcon from "../images/settings-icon.png"
// import { useNavigate } from "react-router-dom"

// export default function StudentHome() {
//     const navigate = useNavigate();
//     const handleButtonClick = (path) => {
//         navigate(path)
//     }
//     return (
//         <div>
//             <div class="container text-center">
//                 <div class="row" style={{ marginLeft: '120px' }}>
//                     <div class="col">
//                         <div class="text-start" style={{fontSize:'45px', textAlign: 'left', marginLeft: '-110px', marginTop: '100px'}} >Student Name</div>  
//                     </div>  
//                     <div class="col">
//                         <div class="row">
//                             <div class="col">
//                                 <button class="btn" style={{ marginTop: '100px', marginLeft: '35px', height: '50px', width: '291px', fontSize: '30px', color: 'white', lineHeight: "7px", alignItems: 'center', backgroundColor: '#86C880', borderRadius: '0'}}
//                                 onClick={() => handleButtonClick('/student-create-request')}>+ Schedule a lesson</button>
//                             </div>
//                             <div class="col">
//                                 <img src={CalendarIcon} style={{marginTop: '100px', height: '61px', width: '61px', marginLeft: '70px'}}></img>
//                             </div>
//                             <div class="col">
//                                 <img src={SettingsIcon} style={{marginTop: '100px', height: '61px', width: '61px'}}></img>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row" style={{ marginTop: '50px' }}>
//                     <div class="col">
//                         <div style={{textAlign:'left', fontSize:'35px', marginLeft: '-10px', marginBottom: '20px'}}> Upcoming Sessions </div>
//                             <UpcomingSessionTile subject="Calculus" time="11:45am" date="Friday, January 19" tutor="Alessia L." icon={AlessiaProfile}/>
//                             <UpcomingSessionTile subject="Fluid Mechanics" time="1:30pm" date="Saturday, January 20" tutor="Birks S." icon={BirksProfile}/>
//                     </div>
//                     <div class="col">
//                         <div style={{textAlign:'left', fontSize:'35px', marginLeft: '-10px'}}> Current Requests </div>
//                         <CurrentRequestTile subject="Thermodynamics" time="11:45am" date="Friday, January 19"/>

//                     </div>
//                     <div class="col">
//                         <div style={{textAlign:'left', fontSize:'35px', marginLeft: '-10px'}}> Past Sessions </div>
//                         <PastSessionTile subject="Calculus" time="11:45am" date="Friday, January 19" icon={AlessiaProfile} tutor="Riddhi B."/>
//                         <PastSessionTile subject="Data Structures" time="11:45am" date="Friday, January 19" icon={AlessiaProfile} tutor="Eula B."/>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     )
// }

import CalendarIcon from "../images/calendar-icon.png"
import SettingsIcon from "../images/settings-icon.png"

import React from 'react';

const styles = {
    dashboardContainer: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        margin: '0 0 0 0',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    section: {
        marginBottom: '20px',
    },
    sectionHeader: {
        fontSize: '24px',
        color: '#333',
        borderBottom: '2px solid #666',
        paddingBottom: '5px',
        marginBottom: '10px',
    },
    sessionCard: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        border: 'none',
        borderRadius: '5px',
        padding: '10px 15px',
        cursor: 'pointer',
    },

    cancelBtn: {
        backgroundColor: '#f44336',
        color: 'white',
    },
    scheduleBtn: {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    detailsLink: {
        color: '#2196F3',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    newRequestButton: {
        backgroundColor: '#4CAF50', // A green color for the button
        color: 'white',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '5px',
        border: 'none',
    },
    calendarButtonStyle: {
        backgroundColor: '#f0f0f0', // A neutral color for the calendar button
        border: 'none',
        cursor: 'pointer',
        padding: '10px',
        marginRight: '10px', // Spacing between the calendar button and the name
        borderRadius: '5px'
        // Add more styles as needed
    },

    settingsButtonStyle: {
        backgroundColor: '#f0f0f0', // A neutral color for the calendar button
        border: 'none',
        cursor: 'pointer',
        padding: '10px',
        marginRight: '10px', // Spacing between the calendar button and the name
        borderRadius: '5px'
    }
};

function Dashboard() {
    // Normally you would fetch this data from a server
    const upcomingSessions = [
        { subject: 'Calculus', tutor: 'Alessia L.', date: '21 January', time: '11:00 am' },
        { subject: 'Fluid Mechanics', tutor: 'Birks S.', date: '20 January', time: '1:30 pm' },
    ];

    const currentRequests = [
        { subject: 'Thermodynamics', status: 'pending', date: 'Thursday', time: '11:45 am' },
    ];

    const pastSessions = [
        { subject: 'Calculus', tutor: 'Riddhi B.' },
        { subject: 'Data Structures', tutor: 'Eula B.' },
    ];

    const handleCreateNewRequest = () => {
        console.log('Create new request button clicked');
    }

    const handleCalendarClick = () => {
        console.log('Calendar button clicked');
    };
    
      const handleSettingsClick = () => {
        console.log('Settings button clicked')
    };
    return (
        <div style={styles.dashboardContainer}>
                <div className="row">
                    <div className="col-10">
                    <div style={styles.header}>
                        <h1>Hi Alessia,</h1>
                    </div>
                    </div>
                    {/* Other header elements like navigation would go here */}
                    <div className="col"> 
                        <div className="row"> 
                            <img src={CalendarIcon} style={{ height: '60px', width: '80px', display: 'flex'}} onClick={handleCalendarClick}></img>
                            <img src={SettingsIcon} style={{ height: '60px', width: '80px', display: 'flex'}} onClick={handleSettingsClick}></img>
                        </div>
                    </div>
                </div>
            {/* <div className='row'> */}
            <div style={styles.section}>
                <h2 style={styles.sectionHeader}>Upcoming Sessions</h2>
                {upcomingSessions.map((session, index) => (
                    <div key={index} style={styles.sessionCard}>
                        <span>{session.subject} with {session.tutor}</span>
                        <span>{session.date} at {session.time}</span>
                        <button style={{ ...styles.button, ...styles.cancelBtn }} onClick={() => console.log('Cancel session')}>Cancel</button>
                    </div>
                ))}
            </div>
            <div style={styles.section}>
                <h2 style={styles.sectionHeader}>Current Requests
                </h2>
                <div style={{ textAlign: 'center', margin: '20px' }}>
                </div>
                {currentRequests.map((request, index) => (
                    <div key={index} style={styles.sessionCard}>
                        <span>{request.subject}</span>
                        <span>{request.status} - {request.date} at {request.time}</span>
                        <button style={{ ...styles.button, ...styles.cancelBtn }} onClick={() => console.log('Cancel request')}>Cancel</button>
                    </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'left', margin: '20px 0' }}>
                    <button style={styles.newRequestButton} onClick={handleCreateNewRequest}>
                        + Schedule a new lesson
                    </button>
                </div>
            </div>
            <div style={styles.section}>
                <h2 style={styles.sectionHeader}>Past Sessions</h2>
                {pastSessions.map((session, index) => (
                    <div key={index} style={styles.sessionCard}>
                        <span>{session.subject} with {session.tutor}</span>
                        <a style={styles.detailsLink} onClick={() => console.log('View details')}>View Details</a>
                    </div>
                ))}
            </div>
            {/* </div> */}
        </div>
    );
}

export default Dashboard;