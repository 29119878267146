// export default Navbar;
import Logo from '../images/kazarma-logo.png';
import { useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navbarStyle = {
        display: 'flex',
        justifyContent: isMobileView ? 'space-between' : 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '1rem',
        flexDirection: isMobileView ? 'row' : 'row',
        height: '50px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        fontFamily: 'Roboto, sans-serif', // Adding Roboto font family
        fontSize: '15px', // Setting font size to 20px
    };

    const mobileMenuIconStyle = {
        fontSize: '2rem',
        color: 'black',
        cursor: 'pointer',
        display: isMobileView ? 'block' : 'none',
    };

    const logoStyle = {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      width: '120px',
      margin: '0.2rem 1.5rem 0 0',
    };

    const navLinksStyle = {
        listStyle: 'none',
        display: isMobileView ? 'none' : 'flex',
        margin: '0',
        padding: '0',
        alignItems: 'center',
    };

    const navLinkItemStyle = {
        padding: '0 1rem',
        marginTop: isMobileView ? '0': '4.5px',
        marginBottom: isMobileView ? '10px': '0px',
    };

    const navLinkStyle = {
        color: 'black',
        textDecoration: 'none',
        className: 'navLink' // Add this line if using external CSS
    };

    const mobileNavLinksStyle = {
        listStyle: 'none',
        display: isMobileMenuOpen ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'left',
        width: '100%',
        position: 'absolute',
        top:'50px',
        left: '0',
        backgroundColor: '#fff',
        textAlign: 'left',
        paddingTop: '1rem',
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    
    const navigate = useNavigate();
    const handleButtonClick = () => {
      navigate('/home')
    }

    return (
        <nav style={navbarStyle}>
          <img style={logoStyle} src={Logo} alt="Logo" onClick={handleButtonClick} />
            <div style={mobileMenuIconStyle} onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? '\u00D7' : '\u2630'} {/* This uses Unicode characters */}
            </div>
            <ul style={isMobileView && isMobileMenuOpen ? mobileNavLinksStyle : navLinksStyle}>
            <li style={navLinkItemStyle}>
                <a href="/home" className="navLink" style={navLinkStyle}>Home</a>
            </li>
            <li style={navLinkItemStyle}>
                <a href="/engineering-tutoring-services" className="navLink" style={navLinkStyle}>Engineering Tutoring Services</a>
            </li>
            <li style={navLinkItemStyle}>
                <a href="/engineering-college-prep-and-advising" className="navLink" style={navLinkStyle}>Engineering College Prep and Advising</a>
            </li>
            </ul>
        </nav>
    );
};

export default Navbar;
