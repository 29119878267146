import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import RedFlag from "../../images/red-flag.png";
import PersonalizedPairing from "../../images/personalized-pairing.png";
import ExpertTutors from "../../images/expert-tutors.png";
import FlexibleScheduling from "../../images/flexible-scheduling.png";
import DiverseSubjects from "../../images/diverse-subjects.png";
import AerospaceEngineering from "../../images/engineering-major-logos/aerospace-engineering.png";
import Bioengineering from "../../images/engineering-major-logos/bioengineering.png";
import CivilEngineering from "../../images/engineering-major-logos/civil-engineering.png";
import ChemicalEngineering from "../../images/engineering-major-logos/chemical-engineering.png";
import ComputerScience from "../../images/engineering-major-logos/computer-science.png";
import DataScience from "../../images/engineering-major-logos/data-science.png";
import ElectricalEngineering from "../../images/engineering-major-logos/electrical-engineering.png";
import EnergyEngineering from "../../images/engineering-major-logos/energy-engineering.png";
import EngineeringAndPublicPolicy from "../../images/engineering-major-logos/engineering-and-public-policy.png";
import EngineeringMathAndStats from "../../images/engineering-major-logos/engineering-math-and-stats.png";
import EngineeringPhysics from "../../images/engineering-major-logos/engineering-physics.png";
import EnvironmentalEngineering from "../../images/engineering-major-logos/environmental-engineering.png";
import FinancialEngineering from "../../images/engineering-major-logos/financial-engineering.png";
import IndustrialEngineeringAndOperationsResearch from "../../images/engineering-major-logos/industrial-engineering-and-operations-research.png";
import MaterialsScienceAndEngineering from "../../images/engineering-major-logos/materials-science-and-engineering.png";
import MechanicalEngineering from "../../images/engineering-major-logos/mechanical-engineering.png";
import NuclearEngineering from "../../images/engineering-major-logos/nuclear-engineering.png";
import UndeclaredEngineering from "../../images/engineering-major-logos/undeclared-engineering.png";

const styles = {
  container: {
    fontFamily: 'Roboto, sans-serif',
    marginTop: '20px',
  },
  header: {
    marginTop: '30px',
    textAlign: 'center',
    fontSize: '35px',
  },
  subHeader: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '15px',
    color: '#847F7F',
  },
  launchInfo: {
    textAlign: 'center',
    padding: '20px',
    fontSize: '18px',
  },
  launchHeader: {
    textAlign: 'center',
    marginTop: '30px',
    marginRight: '20px',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  redFlagImage: {
    marginRight: '10px',
    width: '30px',
    height: '30px',
  },
  launchInfo: {
    textAlign: 'center',
    padding: '10px',
    margin: '0 auto',
    maxWidth: '500px',
    fontSize: '18px',
  },
  buttonStyle: {
    padding: "0.5rem 1rem",
    marginTop: "0.5rem",
    margin: '20px',
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "240px",
    fontWeight: "bold",
  },
  buttonSubheading: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  buttonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  howItWorksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#689EF0',
    padding: '20px 0',  
    width: '100%',
    boxSizing: 'border-box', // Makes sure padding and border are included in the width
  },
  howItWorksHeader: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '25px',
    padding: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  howItWorksItemContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap', // Allows items to wrap in the next line if necessary
  },
  howItWorksItem: {
    padding: '10px 20px',
    fontSize: '18px',
    color: '#FFF',
    marginBottom: '30px', // adds some space between rows
    width: '320px',
    alignItems: 'center', // centers the text
  },
  whyChooseKazarmaContainer: {
    backgroundColor: '#FFF',
    padding: '20px 0',  
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  whyChooseKazarmaHeader: {
    color: '#00000',
    textAlign: 'center',
    fontSize: '25px',
    padding: '20px',
    fontWeight: 'bold',
  },
  whyChooseKazarmaItemsContainer: {
    display: 'flex',
    flexWrap: 'wrap', 
    maxWidth: '1000px',
    margin: 'auto'
  },
  whyChooseKazarmaItem: {
    padding: '50px',
    fontSize: '18px',
    color: '#000',
    flex: '1 0 50%'
  },
  whyChooseKazarmaImage: {
    width: '90px',
    height: '90px'
  },
  majorsWeCoverContainer: {
    backgroundColor: '#EBEBEB',
    padding: '20px 0',  
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  majorsWeCoverContainerHeader: {
    color: '#00000',
    textAlign: 'center',
    fontSize: '25px',
    padding: '20px',
    fontWeight: 'bold',
  },
  majorsWeCoverItemsContainer: {
    display: 'flex', 
    flexWrap: 'wrap', 
    justifyContent: 'center',
    gap: '20px',
    padding: '20px'
  },
  majorsWeCoverItem: {
    fontSize: '14px'
  },
  majorsWeCoverImage: {
    width: '50px',
    height: '50px'
  }
};

const majors = [
  { name: 'Aerospace Engineering', icon: AerospaceEngineering },
  { name: 'Bioengineering', icon: Bioengineering },
  { name: 'Civil Engineering', icon: CivilEngineering },
  { name: 'Chemical Engineering', icon: ChemicalEngineering },
  { name: 'Computer Science', icon: ComputerScience },
  { name: 'Data Science', icon: DataScience },
  { name: 'Environmental Engineering', icon: EnvironmentalEngineering },
  { name: 'Energy Engineering', icon: EnergyEngineering },
  { name: 'Financial Engineering', icon: FinancialEngineering },
  { name: 'Electrical Engineering', icon: ElectricalEngineering },
  { name: 'Engineering Math & Stats', icon: EngineeringMathAndStats },
  { name: 'Engineering Physics', icon: EngineeringPhysics },
  { name: 'Engineering and Public Policy', icon: EngineeringAndPublicPolicy },
  { name: 'Industrial Engineering & Operations Research', icon: IndustrialEngineeringAndOperationsResearch },
  { name: 'Materials Science & Engineering', icon: MaterialsScienceAndEngineering },
  { name: 'Mechanical Engineering', icon: MechanicalEngineering },
  { name: 'Nuclear Engineering', icon: NuclearEngineering },
  { name: 'Undeclared Engineering', icon: UndeclaredEngineering }
];




const EngineeringTutoringServices = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>Engineering Tutoring Services</div>
      <div style={styles.subHeader}>engineering tutoring services for college students and working professionals</div>
        <div style={styles.launchHeader}> 
        <img src={RedFlag} style={styles.redFlagImage}/>
        Launching Summer 2024!
        </div>
      <div style={styles.launchInfo}>Fill out the interest form as a future student or a potential tutor to be notified when our tutoring service is launched.</div>
      <div style={styles.buttonContainer}>
      <Button style={{...styles.buttonStyle,  color:"#FFF", backgroundColor:"#FF9C00"}} href="https://pbikdiv1rm1.typeform.com/to/fJZp8KFn">
        Tutoring Interest Form
        <div style={styles.buttonSubheading}> for interested students</div>
      </Button>
      <Button style={{...styles.buttonStyle,  color:"#FFF", backgroundColor:"#235196"}} href="https://pbikdiv1rm1.typeform.com/to/DRsc3sjs">
        Tutoring Interest Form
        <div style={styles.buttonSubheading}> for potential tutors</div>
      </Button>
      </div>
      <div style={{...styles.howItWorksContainer}}>
        <div style={styles.howItWorksHeader}>How It Works</div>
        <div style={styles.howItWorksItemContainer}>
          <div style={styles.howItWorksItem}>
          <strong>1. Sign up:</strong> <br />
          Create your profile and tell us about your tutoring needs, learning style, and what you value in a potential tutor.
          </div>
          <div style={styles.howItWorksItem}>
          <strong>2. Request a lesson:</strong> <br />
          Fill out a lesson request with your subject of interest, and any questions or concepts you are struggling with.        
          </div>
          <div style={styles.howItWorksItem}>
          <strong>3. We'll do the match:</strong> <br />
          Our algorithm will match you with the perfect tutor, who specializes in the topic and can address your request.  
          </div>
          <div style={styles.howItWorksItem}>
          <strong>4. Engage and Learn:</strong> <br />
          Schedule a session with our tutor and start learning. Our tutors are experts in their field and will help you master the subject.
          </div>
        </div>
      </div>
      <div style={{...styles.whyChooseKazarmaContainer}}>
        <div style={styles.whyChooseKazarmaHeader}>Why Choose Kazarma?</div>
        <div style={styles.whyChooseKazarmaItemsContainer}>
            <div style={styles.whyChooseKazarmaItem}>
              <div className="row">
              <div className="col-4">
                <img src={PersonalizedPairing} style={styles.whyChooseKazarmaImage}/>
              </div>
              <div className="col-8">
              <strong>Personalized Pairing </strong> <br />
              Our innovative matching system ensures you get the tutor who understands your learning style and challenges.        
              </div>
              </div>
            </div>
            <div style={styles.whyChooseKazarmaItem}>
            <div className="row">
              <div className="col-4">
                <img src={ExpertTutors} style={styles.whyChooseKazarmaImage}/>
              </div>
              <div className="col-8">
              <strong>Expert Tutors</strong> <br />
              Each tutor is rigorously vetted by our team and has a comprehensive background in engineering education and industry experience.  
              </div>
            </div>
            </div>
            <div style={styles.whyChooseKazarmaItem}>
            <div className="row">
              <div className="col-4">
                <img src={FlexibleScheduling} style={styles.whyChooseKazarmaImage}/>
              </div>
              <div className="col-8">
              <strong>Flexible Scheduling</strong> <br />
              Find a tutor who fits your busy life and schedule. Choose the time and pace of your learning with our scheduling system.  
              </div>
              </div>
            </div>
            <div style={styles.whyChooseKazarmaItem}>
            <div className="row">
              <div className="col-4">
                <img src={DiverseSubjects} style={styles.whyChooseKazarmaImage}/>
              </div>
              <div className="col-8">
              <strong>Diverse Subjects</strong> <br />
              From Mechanical Engineering to Computer Science, our tutors cover all major STEM and engineering disciplines. 
              </div>
              </div>
            </div>
        </div>
      </div>
      <div style={{...styles.majorsWeCoverContainer}}>
        <div style={styles.majorsWeCoverContainerHeader}>Majors we cover</div>
        <div style={styles.majorsWeCoverItemsContainer}>
          {majors.map((major) => (
            <div key={major.name} style={{ width: '250px', textAlign: 'center', padding: '10px', borderRadius: '10px' }}>
              <img src={major.icon} alt={major.name} style={{ width: '50px', height: '50px', marginBottom: '10px'}} />
              <div>{major.name}</div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
)
}

export default EngineeringTutoringServices;