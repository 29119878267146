import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import TutoringSubjects from './pages/TutoringSubjects'
import NoPage from './pages/NoPage'
// import Header from './components/Header'
import Navbar from './components/Navbar'
import Footer from './pages/Footer'
import EngineeringTutoringServices from './pages/engineering-tutoring-services/EngineeringTutoringServices'
import StudentLogin from './pages/StudentLogin'
import StudentSignUp from './pages/StudentSignUp'
import TutorLogin from './pages/TutorLogin'
import TutorApply from './pages/TutorApply'
import TutorApplySubmitted from './pages/TutorApplySubmitted'
import StudentSignUpConfirmation from './pages/StudentSignUpConfirmation'
import StudentHome from './pages/StudentHome'
import StudentCreateRequest from './pages/StudentCreateRequest'
import StudentReviewTutor from './pages/StudentReviewTutor'
import EngineeringCollegePrepAndAdvising from './pages/engineering-college-prep-and-advising/EngineeringCollegePrepAndAdvising'

export default function App() {
    return (
        <div>
            <BrowserRouter>
            <Navbar />
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="/home" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/tutoring-subjects" element={<TutoringSubjects />} />
                    <Route path="/engineering-tutoring-services" element={<EngineeringTutoringServices />} />
                    <Route path="/engineering-college-prep-and-advising" element={<EngineeringCollegePrepAndAdvising />} />
                    <Route path="/student-login" element={<StudentLogin />} />
                    <Route path="/student-sign-up" element={<StudentSignUp />} />
                    <Route path="/tutor-login" element={<TutorLogin />} />
                    <Route path="/tutor-apply" element={<TutorApply />} />
                    <Route path="/tutor-apply-submitted" element={<TutorApplySubmitted/>} />
                    <Route path="/student-sign-up-confirmation" element={<StudentSignUpConfirmation/>} />
                    <Route path="/student-home" element={<StudentHome/>} />
                    <Route path="/student-create-request" element={<StudentCreateRequest/>} />
                    <Route path="/student-review-tutor" element={<StudentReviewTutor/>} />
                    <Route path="*" element={<NoPage/>} />
                </Routes>
            <Footer />
            </BrowserRouter>
        </div>
    )
}